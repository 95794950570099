import React, { useState, useEffect } from 'react'
import Selectpicker from '../../shared/inputs/selectpicker'
import SwitchInput from '../../shared/inputs/SwitchInput'

const ConfigClaimBanner = (props) => {
  const [state, setState] = useState({...props.banner_info});
  const [configurationType, setConfigurationType] = useState(String(props.configurationType));

  useEffect(() => {
    subscribeEvents();

    return () => PubSub.unsubscribe('ClaimConfigurationTypeChanged')
  }, [])

  const subscribeEvents = () => {
    PubSub.subscribe('ClaimConfigurationTypeChanged', (topic, claimType) => {
      setConfigurationType(String(claimType))
    })
  }

  const setCustomValidity = (className, message) => {
    const element = document.querySelector(className);
    if (!element) return;

    element.setCustomValidity(message)
  }

  const handleNameChange = (fieldName, e) => {
    let newValue = e.target.value
    setState(prevState => ({
      ...prevState,
      [fieldName]: newValue
    }))
    setCustomValidity('.tpa_name', "");
    setCustomValidity('.tpa_link', "");
    setCustomValidity('.claim_portal_text', "");
    setCustomValidity('.hr_email', "");
    setCustomValidity('.hr_email_text', "");
  }

  const handleChange = (fieldName, checked) => {
    setState(prevState => ({
      ...prevState,
      [fieldName]: checked
    }))
  }

  const showError = (className, errorMessage) => {
    setCustomValidity(className, errorMessage);
    document.querySelector(className).reportValidity();
  }

  const validateTpaName = () => {
    const tpaName = state.tpa_name;
    if (tpaName && tpaName.trim()) return true;

    showError('.tpa_name', "Please fill out this field.")
    return false;
  }

  const validateTpaLink = () => {
    const isSelfAdminCustomer = props.company_level ? state.is_self_admin_customer : false;
    if (isSelfAdminCustomer) return true;

    const tpaLink = state.tpa_link;
    const claimPortalText = state.claim_portal_text;
    const isTpaLinkPresent = tpaLink && tpaLink.trim();
    const isTpaTextPresent = claimPortalText && claimPortalText.trim();
    if (isTpaLinkPresent && isTpaTextPresent) return true;

    if (!isTpaLinkPresent) {
      showError('.tpa_link', "Please fill out this field.");
    } else {
      showError('.claim_portal_text', "Please fill out this field.");
    }

    return false;
  }

  const validateHrDetails = () => {
    const isSelfAdminCustomer = props.company_level ? state.is_self_admin_customer : false;
    if (!isSelfAdminCustomer) return true;

    const hrEmail = state.hr_email;
    const hrEmailText = state.hr_email_text;
    const isHrEmailPresent = hrEmail && hrEmail.trim();
    const isHrEmailTextPresent = hrEmailText && hrEmailText.trim();
    let isHrEmailValid = false;
    if (isHrEmailPresent) {
      const emailRegex = /^[\w'\-.\(\)]+@[a-z\d\-.]+\.[a-z]+$/i;
      isHrEmailValid = emailRegex.test(hrEmail);
    }
    if (isHrEmailPresent && isHrEmailTextPresent && isHrEmailValid) return true;

    if (!isHrEmailPresent) {
      showError('.hr_email', "Please fill out this field.");
    } else if (!isHrEmailValid) {
      showError('.hr_email', "Enter a valid email address.");
    } else {
      showError('.hr_email_text', "Please fill out this field.");
    }

    return false;
  }

  const validateFields = () => {
    const enabledEducationBanner = state.enabled_education_banner;
    if (!enabledEducationBanner) return true;

    if (!validateTpaName()) return false;
    if (!validateTpaLink()) return false;
    if (!validateHrDetails()) return false;

    return true;
  }

  const saveBannerInfo = () => {
    if (!validateFields()) return;

    let companyUrl = props.company_level ? 'companies/' :''
    let paramName = props.company_level ? 'company_claim_configuration' : 'claim_configuration'
    let url = `/admin/${companyUrl}claim_configurations/${state.id}/update_banner_info`
    $.ajax({
      method: 'PUT',
      url: url,
      data: {[paramName] : state},
      success: (response) => {
        if (response.success){
          toastr.success(response.message)
        } else {
          toastr.error(response.message)
        }
      },
      error: (response) => {
        toastr.error(response.responseJSON.message)
      }
    })
  }

  const renderSelfAdminCustomerToggle = () => {
    if (!props.company_level) {
      return (<div className="col-sm-4" />)
    }

    const isClaimIntegrated = configurationType == '0';

    return (
      <div className="col-sm-4">
        <div className="col-md-6 switch-label">
          <label>Self-Admin Customer</label>
        </div>
        <div className="col-md-1">
          <SwitchInput
            name="self_admin_customer"
            checked={state.is_self_admin_customer}
            id="is_self_admin_customer"
            disabled={isClaimIntegrated || !state.enabled_education_banner || !props.sharingEnabled}
            onChange={(checked) => handleChange('is_self_admin_customer',checked)}
          />
        </div>
      </div>)
  }

  const renderEnableBanner = () => {
    return (
      <div className="form-group" style={{
        paddingTop: 12
      }}>
        <div className="row">
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
              <label>Enable Claims Education Banner</label>
            </div>
            <div className="col-md-1">
              <SwitchInput
                name="enable_banner"
                checked={state.enabled_education_banner}
                id="enabled_education_banner"
                onChange={(checked) => handleChange('enabled_education_banner',checked)}
              />
            </div>
          </div>
          { renderSelfAdminCustomerToggle() }
          <div className="col-sm-2">
            <div className="col-md-9 switch-label">
            </div>
            <div className="col-md-1">
              <button
                className="btn btn-primary btn-sm"
                onClick={saveBannerInfo}
              >Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderTpaNameField = () => {
    return (
      <>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
                <label>TPA Display Name</label>
                <input
                  type="text"
                  className="form-control tpa_name"
                  name="tpa_name"
                  value={ state.tpa_name }
                  disabled={!state.enabled_education_banner}
                  onChange={ (e) => handleNameChange('tpa_name',e) }
                  style={{
                    width: 500
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>)
  }

  const renderTpaLinkFields = () => {
    const isSelfAdminCustomer = props.company_level ? state.is_self_admin_customer : false;
    if (isSelfAdminCustomer) return null;

    return (
      <>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
                <label>TPA Claims Portal Link</label>
                <input
                  type="text"
                  className="form-control tpa_link"
                  name="tpa_link"
                  value={ state.tpa_link }
                  disabled={!state.enabled_education_banner}
                  onChange={ (e) => handleNameChange('tpa_link', e) }
                  style={{
                    width: 500
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
              <label>TPA Claims Portal Text to Display</label>
              <input
                type="text"
                className="form-control claim_portal_text"
                name="claim_portal_text"
                value={ state.claim_portal_text }
                disabled={!state.enabled_education_banner}
                onChange={ (e) => handleNameChange('claim_portal_text', e) }
                style={{
                  width: 500
                }}
              />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderHrDetailsFields = () => {
    const isSelfAdminCustomer = props.company_level ? state.is_self_admin_customer : false;
    if (!isSelfAdminCustomer) return null;

    return (
      <>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
                <label>HR Email Address</label>
                <input
                  type="text"
                  className="form-control hr_email"
                  name="hr_email"
                  value={ state.hr_email }
                  disabled={!state.enabled_education_banner}
                  onChange={ (e) => handleNameChange('hr_email', e) }
                  style={{
                    width: 500
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9">
              <label>HR Email Address Text to Display</label>
              <input
                type="text"
                className="form-control hr_email_text"
                name="hr_email_text"
                value={ state.hr_email_text }
                disabled={!state.enabled_education_banner}
                onChange={ (e) => handleNameChange('hr_email_text', e) }
                style={{
                  width: 500
                }}
              />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const bannerToggles = () => {
    const isClaimIntegrated = configurationType == '0';
    const isSelfAdminCustomer = props.company_level ? state.is_self_admin_customer : false;
    return (
      <>
      <div className="form-group">
        <div className="row">
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
              <label >RLSD > 30 days in Future</label>
            </div>
            <div className="col-md-1">
              <SwitchInput
                name="rlsd_gt_30_future"
                checked={state.rlsd_gt_30_future}
                id="rlsd_gt_30_future"
                disabled={!state.enabled_education_banner}
                onChange={(checked) => handleChange('rlsd_gt_30_future',checked)}
              />
            </div>
          </div>
          <div className="col-sm-2">
          </div>
          <div className="col-sm-5">
            <div className="col-md-9 switch-label">
              <label >Post Share Action Required</label>
            </div>
            <div className="col-md-1">
              <SwitchInput
                name="post_share_required"
                checked={state.post_share_required}
                id="post_share_required"
                disabled={isClaimIntegrated || !state.enabled_education_banner || isSelfAdminCustomer}
                onChange={(checked) => handleChange('post_share_required',checked)}
              />
            </div>
          </div>
        </div>
      </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >RLSD = 30 days in Future</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="rlsd_lt_30_future"
                  checked={state.rlsd_lt_30_future}
                  id="rlsd_lt_30_future"
                  disabled={!state.enabled_education_banner}
                  onChange={(checked) => handleChange('rlsd_lt_30_future',checked)}
                />
              </div>
            </div>
            <div className="col-sm-2">
            </div>
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >Edit Alert</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="enabled_edit_alert"
                  checked={state.enabled_edit_alert}
                  id="enabled_edit_alert"
                  disabled={!state.enabled_education_banner}
                  onChange={(checked) => handleChange('enabled_edit_alert',checked)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >RLSD in the Past</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="rlsd_past"
                  checked={state.rlsd_past}
                  id="rlsd_past"
                  disabled={!state.enabled_education_banner}
                  onChange={(checked) => handleChange('rlsd_past',checked)}
                />
              </div>
            </div>
            <div className="col-sm-2">
            </div>
            <div className="col-sm-5">
              <div className="col-md-9 switch-label">
                <label >Post Edit Action Required</label>
              </div>
              <div className="col-md-1">
                <SwitchInput
                  name="post_edit_action_required"
                  checked={state.post_edit_action_required}
                  id="post_edit_action_required"
                  disabled={!state.enabled_education_banner}
                  onChange={(checked) => handleChange('post_edit_action_required',checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {renderEnableBanner()}
      {renderTpaNameField()}
      {renderTpaLinkFields()}
      {renderHrDetailsFields()}
      {bannerToggles()}
    </>
  )
}

export default ConfigClaimBanner;
