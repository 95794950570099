import React, { useState } from "react";
import { Container, makeStyles, Typography, Button, FormHelperText } from "@material-ui/core";
import AuthLayout from "./AuthLayout";
import FormInput from './FormInput';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  language: '',
  company_code: '',
  password: '',
  password_confirmation: '',
  error: false,
  errorMessage: {}
}
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const SignUp = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);
  const [isCompanyCodeDisabled, setIsCompanyCodeDisabled] = useState(true)
  const [showHelperText, setShowHelperText] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(user => ({ ...user, [name]: value }))
  }

  const handleNext = () => {
    let isError = false;
    if (user.first_name.length === 0) {
      isError = true;
      setUser({
        ...user,
        error: true,
        errorMessage: { first_name: "Enter your first name" }
      });
    }
    if (user.last_name.length === 0) {
      isError = true;
      setUser(prev => ({
        ...prev,
        error: true,
        errorMessage: { ...prev.errorMessage, last_name: "Enter your last name" }
      }))
    }
    if (user.email.length === 0 || !EMAIL_REGEX.test(user.email) || !checkEmail(user.email)) {
      isError = true
      setUser(prev => ({
        ...prev,
        error: true,
        errorMessage: { ...prev.errorMessage, email: "Enter valid email address" }
      }))
    }
    if (props.allow_non_corporate_emails) {
      if (!isCompanyCodeDisabled && user.company_code.length === 0) {
        isError = true
        setUser(prev => ({
          ...prev,
          error: true,
          errorMessage: { ...prev.errorMessage, company_code: "Enter your company code" }
        }))
      }
    }
    if (user.password.length === 0) {
      isError = true
      setUser(prev => ({
        ...prev,
        error: true,
        errorMessage: { ...prev.errorMessage, password: "Enter password" }
      }))
    }
    if (user.password_confirmation.length === 0) {
      isError = true
      setUser(prev => ({
        ...prev,
        error: true,
        errorMessage: { ...prev.errorMessage, password_confirmation: "Enter your password confirmation" }
      }))
    }
    if (!isError) {
      setUser({
        ...user,
        error: false,
        errorMessage: {}
      });
    }
    return isError
  }

  const checkEmail = (email) => {
    if (isCompanyCodeDisabled) {
      return checkValidDomain(email)
    } else {
      return true
    }
  }

  const checkValidDomain = (email) => {
    let requested_domain, splitted_email, valid_domain;
    splitted_email = email.split('@');
    requested_domain = splitted_email[splitted_email.length - 1];
    valid_domain = props.valid_domains.indexOf(requested_domain.toLowerCase()) >= 0;

    return valid_domain;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!handleNext()) {
      let method = 'POST'
      let url = '/users'
      setSubmitted(true)
      $.ajax({
        method: method,
        url: url,
        data: JSON.stringify({ user: user }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        success: (response) => {
          setSubmitted(false)
          window.location = response.redirect_location
        },
        error: (err) => {
          setSubmitted(false)
          toastr.error(JSON.parse(err.responseText).message)
        }
      })
    }
  }

  const companyCode = (email) => {
    if (props.allow_non_corporate_emails) {
      if (email.length > 0 && EMAIL_REGEX.test(email)) {
        let valid_domain = checkValidDomain(email)
        setIsCompanyCodeDisabled(valid_domain)
      }
    }
  }

  return (
    <AuthLayout isConfidentialPlanning={props.is_confidential_planning}>
      <Container maxWidth="sm">
        <div className={classes.registrationDiv}>
          <div className={classes.headings}>
            <span className={classes.heading1}><span className="underline">Register for a new Account</span></span>
            <span className={classes.subHead}>
              Have an account already?
              <a className={classes.signin} href="/users/sign_in">
                Sign in
              </a>
            </span>
          </div>

          <form name="form" onSubmit={handleSubmit} style={{ width: '100%' }}>
            <div className={classes.formDiv}>
              <FormInput
                type="text"
                name="first_name"
                formLabel="First Name"
                onChange={handleChange}
                error={!!user.errorMessage.first_name}
                helperText={user.errorMessage.first_name}
              />
              <FormInput
                type="text"
                name="last_name"
                formLabel="Last Name"
                onChange={handleChange}
                error={!!user.errorMessage.last_name}
                helperText={user.errorMessage.last_name}
              />
              <FormInput
                type="email"
                name="email"
                formLabel="Email"
                onKeyUp={(e) => companyCode(e.target.value)}
                onChange={handleChange}
                error={!!user.errorMessage.email}
                helperText={user.errorMessage.email}
              />
              {props.allow_non_corporate_emails ?
                <FormInput
                  type="text"
                  name="company_code"
                  formLabel="Company Code"
                  disabled={isCompanyCodeDisabled}
                  onChange={handleChange}
                  error={!!user.errorMessage.company_code}
                  helperText={user.errorMessage.company_code}
                />
                : null}
              <FormInput
                type="password"
                name="password"
                formLabel="Password"
                onChange={handleChange}
                onMouseEnter={() => setShowHelperText(true)}
                onMouseLeave={() => setShowHelperText(false)}
                aria-describedby="password-helper-text"
                error={!!user.errorMessage.password}
                helperText={user.errorMessage.password}
              />
              {showHelperText ?
                <FormHelperText id="password-helper-text" className={classes.helperText}>
                  Password must be at least 8 characters, include an uppercase letter, a special character(!, @, $, _, %, #, etc) and a number.
                </FormHelperText> : null}
              <FormInput
                type="password"
                name="password_confirmation"
                formLabel="Confirm Password"
                onChange={handleChange}
                error={!!user.errorMessage.password_confirmation}
                helperText={user.errorMessage.password_confirmation}
              />
              <div className={classes.formGroup} style={{ paddingTop: 30 }}>
                <Button
                  className="primary-login-button"
                  disabled={submitted}
                  style={{ width: '70%' }}
                  type="submit"
                  startIcon={<i className="fa fa-lock" aria-hidden="true"></i>}
                >
                  <span className={classes.labelMedium}> Create Account </span>
                </Button>
                <Typography className={classes.termsAndCondition}>
                  By clicking Register I agree to the {" "}
                  <a href="http://leavelogic.com/terms" target="_blank" className='standard-link'>
                    <b>Terms of Service</b>
                  </a>{", "}
                  <a href="http://leavelogic.com/privacy" target="_blank" className='standard-link'>
                    <b>Privacy Policy</b>
                  </a> {" "} and {" "}
                  <a href="https://leavelogic.com/privacy-notice-for-california-residents/" target="_blank" className='standard-link'>
                    <b>CA Resident Notice at Collection</b>
                  </a>
                </Typography>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </AuthLayout>
  )
};

const useStyles = makeStyles({
  headings: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 70,
  },
  formDiv: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10
  },
  formLabel: {
    paddingBottom: 10
  },
  forgetPassword: {
    float: 'right'
  },
  image: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  termsAndCondition: {
    marginTop: 50,
    fontFamily: "MontserratRegular",
  },
  heading1: {
    fontSize: 32,
    fontFamily: "MontserratExtraBold",
  },
  subHead: {
    fontSize: 18,
    fontFamily: "MontserratRegular",
    color: '#666666',
    margin: '10px 0',
  },
  signin: {
    paddingLeft: 12,
    cursor: 'pointer'
  },
  registrationDiv: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 10px)'
  },
  helperText: {
    backgroundColor: '#f4f0fb',
    padding: 10,
    borderRadius: 10
  }
})

export default SignUp;
